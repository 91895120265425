.lift-block {
    display: block;
    position: relative;
    padding-top: 75%;
    overflow: hidden;
    transition: all .15s;
    margin-bottom: 15px;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 30px;
    }

    &.is-link {
        &:hover, &:focus {
            opacity: 0.9;
        }
    }

    .lift-block-image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: @brand-primary;

        img {
            width: 100%;
            height: auto;
        }
    }

    .lift-block-texts {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: @brand-primary;
        color: #fff;
        padding: 10px 20px;
        transition: all .15s;
    }

    .secondary-text {
        margin: 5px 0;
        text-transform: uppercase;
        font-size: @font-size-small;
    }

    .main-text {
        margin: 5px 0;
        font-family: @font-family-title;
        font-size: @font-size-large;
        line-height: 1.1;
    }
}
