footer.site-footer {
    background: @brand-gray-light;
    border-top: 6px solid @obo-blue-light;
    padding: 50px 0;

    img {
        max-width: 100%;
    }

    .footer-links, .footer-contact, .footer-logo {
        margin-bottom: 20px;
    }

    .footer-links {
        @media (max-width: @screen-xs-max) {
            .pull-right {
                float: left !important;
            }
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                display: block;

                + li {
                    margin-top: 5px;
                }
            }
        }
    }

    .footer-some {
        @media (min-width: @screen-sm-min) {
            text-align: right;
        }

        .clearfix {
            display: inline-block;
            text-align: left;
            padding: 5px;
        }

        .img {
            display: block;
            float: left;
            margin: 0;
        }
    }
}
