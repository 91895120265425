.blog-lift-wrap {
    margin-bottom: 30px;

    .blog-post {
        body.index & {
            @media (max-width: @screen-sm-max) {
                display: none;

                &:nth-child(-n+4) {
                    display: block;
                }
            }
        }
    }

    .blog-lift {
        .lift-block-image {
            background-color: @obo-life-long-blue;
        }

        .lift-block-texts {
            background-color: @obo-life-long-blue;
        }
    }
}

.homepage-blog {
    margin-bottom: 50px;

    .block-button {
        margin-top: -30px;
    }
}

.news-item {
    h3 {
        margin: 7px 0;

        a {
            color: @text-color;

            &:hover, &:focus {
                color: @text-color;
                text-decoration: none;
            }
        }
    }

    & + .news-item {
        margin-top: 20px;
    }
}
