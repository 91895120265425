header.site-header {
    border-top: 6px solid @border-light-blue;
    padding-top: 15px;

    @media (min-width: @screen-md-min) {
        margin-bottom: 20px;
        padding-top: 20px;
    }

    > .container {
        position: relative;
        margin-bottom: 15px;

        @media (min-width: @screen-md-min) {
            margin-bottom: 0;
        }
    }

    .header-logo {
        display: block;
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 2;

        @media (min-width: @screen-md-min) {
            top: 8px;
        }

        img {
            width: 140px;
            height: auto;

            @media (min-width: @screen-md-min) {
                width: 170px;
            }
        }
    }

    .navigation-wrap {
        background-color: darken(@brand-gray-light, 3%);
        padding: 15px 0 30px 0;
        display: none;
        margin-bottom: 40px;

        @media (min-width: @screen-md-min) {
            margin-bottom: 0;
            background-color: transparent;
            padding: 0;
            position: relative;
            height: auto !important;
            display: block !important;
            overflow: visible !important;
        }
    }

    .secondary-navigation {
        border-bottom: 1px solid @border-color;
        margin-bottom: 30px;
        padding-bottom: 15px;

        @media (min-width: @screen-md-min) {
            float: right;
            margin: 0;
            padding: 0;
            padding-left: 210px;
            border: 0;
            margin-top: 9px;
        }

        ul.pages {
            margin: 0px 0px 15px 0px;
            padding: 0;
            list-style: none;

            @media (max-width: @screen-sm-max) {
                .clearfix();
            }

            @media (min-width: @screen-md-min) {
                margin: 0px;
                float: left;
            }

            li {
                color: @text-muted;
                display: block;
                float: left;
                margin: 0px 5px;

                & + li:before {
                    content: "|";
                    padding: 7px 10px 7px 0;
                    display: block;
                    float: left;
                }
            }

            a {
                text-transform: uppercase;
                font-weight: bold;
                color: @text-muted;
                padding: 8px 0;
                display: block;
                float: left;
            }
        }

        .language-menu {
            margin: 0px 0px 15px 0px;
            clear: both;

            @media (min-width: @screen-md-min) {
                float: left;
                clear: none;
                margin: 0px 0px 0px 25px;
            }

            > a {
                text-transform: uppercase;
                padding: 6px 15px;

                @media (max-width: @screen-sm-max) {
                    display: block;
                }

                .caret {
                    margin-left: 10px;
                }
            }

            .dropdown-menu {
                min-width: 100px;
                text-align: center;
                text-transform: uppercase;

                @media (max-width: @screen-sm-max) {
                    width: 100%;
                }
            }
        }

        .search {
            margin: 15px 0 0 0;
            clear: both;

            @media (min-width: @screen-md-min) {
                float: left;
                clear: none;
                margin: 0px 0px 0px 30px;
            }

            @media (max-width: @screen-sm-max) {
                form .input-group {
                    display: table;
                }

                .input-group-btn {
                    width: 1%;
                }
            }

            .search-btn {
                border-color: @brand-primary;
                padding: 4px 20px;
                font-size: 17px;

                &:hover, &:focus, &:active {
                    border-color: darken(@brand-primary, 10%);
                    background-color: darken(@brand-primary, 10%);
                }
            }

            input {
                min-width: 205px;
                padding: 6px 15px;
            }
        }
    }

    .main-navigation {
        clear: both;

        @media (min-width: @screen-md-min) {
            margin-top: 57px;
            margin-left: -15px;
        }

        ul.desktop-menu {
            .clearfix();
            margin: 0;
            padding: 0;
            list-style: none;
            display: none;

            @media (min-width: @screen-md-min) {
                display: block;
            }

            > li.teal-background {
                background-color: @button-teal;

                &:hover {
                    background-color: @button-teal-hover;
                    > a {
                        color: white;
                    }
                }

                > a {
                    color: white;
                }
            }

            > li {
                float: left;
                display: block;
                position: relative;

                &:hover, &:focus, &:active {
                    background-color: darken(@brand-gray-light, 2%);

                    > a {
                        text-decoration: none;
                        color: @obo-greyish;
                    }

                    > .megamenu-container {
                        visibility: visible;
                        z-index: @zindex-navbar;
                        overflow: auto;
                        opacity: 1;
                        transition: all .5s;
                    }
                }

                > a {
                    display: block;
                    font-size: 1.6em;
                    font-family: @font-family-title;
                    color: @text-color;
                    padding: 10px 15px;
                    transition: all .5s;

                    > i {
                        font-size: @font-size-base;
                        margin-left: 3px;
                    }
                }

                &.selected,
                &.ancestor {
                    > a {
                        color: @obo-life-long-blue;
                        text-decoration: underline;
                    }
                }
            }

            .is-megamenu {
                position: static;
            }

            .megamenu-container {
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                z-index: 0;
                padding: 25px 0px 40px 0;
                background-color: darken(@brand-gray-light, 2%);
                visibility: hidden;
                overflow: hidden;
                opacity: 0;
                box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
            }

            .megamenu-title {
                font-size: @font-size-h3;
                font-family: @font-family-title;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 15px;
                display: block;
                color: @text-color;
                margin-bottom: 20px;

                &:hover, &:focus {
                    background-color: transparent;
                    text-decoration: underline;
                }

                i {
                    font-size: @font-size-h4;
                }
            }

            li.level-0 ul {
                padding: 0;
                list-style: none;

                li a {
                    display: block;
                    line-height: 1.1;
                    padding: 5px 0;

                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }

                li.selected > a {
                    color: @obo-life-long-blue;
                    text-decoration: underline;
                }
            }

            li.level-1 {
                .make-md-column(3);
                margin-top: 15px;

                > a {
                    margin-bottom: 5px;
                    font-family: @font-family-title;
                    font-size: 1.35em;
                    color: @text-color;
                }
            }

            li.level-2 {
                > a {
                    color: #666;
                }

                > ul {
                    display: none;
                }

                & + .level-2 {
                    margin-top: 5px;
                }
            }

            li.level-3 {
                display: none;
            }
        }

        ul.mobile-menu {
            margin-top: 30px;
            padding: 0;
            margin: 0;
            list-style: none;
            border: solid @border-color;
            border-width: 0px 1px 1px 1px;

            @media (min-width: @screen-md-min) {
                display: none;
            }

            ul {
                padding: 0;
                margin: 0;
                display: none;
            }

            li.teal-background {

                &:hover {
                    > a {
                        background-color: @button-teal-hover;
                    }
                }

                > a {
                    color: white;
                    background-color: @button-teal;
                }
            }

            li {
                border-top: 1px solid @border-color;
                display: block;
                position: relative;

                &.open {
                    > .mobile-nav-toggle-btn {
                        &:before {
                            content: @fa-var-chevron-up;
                        }
                    }
                }

                &.selected,
                &.ancestor {
                    > ul {
                        display: block;
                    }
                }

                &.selected > a {
                    color: @obo-life-long-blue;
                    text-decoration: underline;
                }

                .mobile-nav-toggle-btn {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 40px;
                    width: 40px;
                    display: block;
                    cursor: pointer;
                    border-left: 1px solid @border-color;
                    background: rgba(0, 0, 0, 0.05);
                    line-height: 40px;
                    text-align: center;
                    z-index: 2;

                    &:before {
                        content: @fa-var-chevron-down;
                        font-family: FontAwesome;
                    }
                }

                a {
                    color: @text-color;
                    background-color: #fff;
                    padding: 10px 55px 10px 15px;
                    display: block;
                }
            }

            li.level-1 {
                border-color: darken(@border-color, 5%);

                .mobile-nav-toggle-btn {
                    border-color: darken(@border-color, 5%);
                }

                > a {
                    padding-left: 22px;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }

            li.level-2 {
                border-color: darken(@border-color, 10%);

                .mobile-nav-toggle-btn {
                    border-color: darken(@border-color, 10%);
                }

                > a {
                    padding-left: 31px;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            li.level-3 {
                border-color: darken(@border-color, 15%);

                .mobile-nav-toggle-btn {
                    border-color: darken(@border-color, 15%);
                }

                > a {
                    padding-left: 40px;
                    background-color: rgba(0, 0, 0, 0.15);
                }
            }

            li.level-4 {
                display: none;
            }
        }
    }
}

.mobile-nav-toggler {
    float: right;
    display: block;
    position: relative;
    height: 60px;
    width: 60px;
    border: 0;
    padding: 0;
    background: transparent;
    outline: 0;
    margin-right: -15px;

    @media (min-width: @screen-md-min) {
        display: none;
    }

    &:hover {
        .hamburger,
        .hamburger:before,
        .hamburger:after {
            background: @link-color;
        }
    }

    body.mobile-nav-open & .hamburger {
        background-color: transparent;

        &:before, &:after {
            top: 0;
            background: @link-color;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }

    .hamburger,
    .hamburger:before,
    .hamburger:after {
        background: @text-color;
        border-radius: 1px;
        width: 22px;
        height: 3px;
        position: absolute;
        display: block;
        content: "";
        cursor: pointer;
        transition: all .4s;
        left: 50%;
        top: 50%;
        margin-top: -2px;
        margin-left: -11px;
    }

    .hamburger:before {
        top: -6px;
        margin-top: 0;
    }

    .hamburger:after {
        top: 6px;
        margin-top: 0;
    }
}
