html {
    font-size: 62.5%;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
    font-family: @font-family-title;
}

.index h3 {
    font-family: @font-family-title;
}

img {
    max-width: 100%;
    @media (max-width: @screen-sm-max) {
        height: auto;
    }
}

.main-content {
    padding: 0 0 60px 0;
}

iframe,
object {
    max-width: 100%;
}

.margin-top-sm {
    @media (max-width: @screen-sm-max) {
        margin-top: 30px;
    }
}

.block-button {
    display: block;
    padding: 10px 15px;
    text-align: center;
    border: 1px solid @border-color;
    color: @link-color;
    transition: all .15s;
    margin-top: 30px;

    &:hover, &:focus {
        color: @link-hover-color;
        background: @brand-gray-light;
        text-decoration: none;
    }
}

.underline-heading {
    margin-top: 0px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    display: block;
    border-bottom: 1px solid @border-color-light;
    font-size: @font-size-h3;
}

/* django-form-desiger */
form {
    .error, .errorlist li {
        color: red;
    }
    .success {
        color: green;
    }
    td, th {
        border: 1px gray solid;
        vertical-align: top;
    }
    label {
        color: gray;
    }
    p label {
        display: inline-block;
        min-width: 5em;
    }
    .required label {
        font-weight: bold;
    }
    .required label:after {
        content: "*";
    }
}

.plugin_googlemap img { max-width: none; }

table {
    .img,
    .img img {
        margin-top: 0px;
    }

    td {
        vertical-align: top;
    }

    &.table {
        // For bootstrap tables
        border-collapse: collapse;
        border-spacing: 0;

        td,
        th {
            padding: 0;
        }
    }
}

.img {
    position: relative;
    margin-bottom: 15px;
    clear: both;
    margin-top: 10px;
    max-width: 100%;

    span.info {
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        width: 100%;
        padding: 5px;
        float: left;
        clear: both;
        color: #fff;
        background: #000;
        background: rgba(0,0,0,.7);
    }
}

.img.None {
    float: left;
    clear: both;

    img {
        float: left;
    }
}

.img.left {
    position: relative;
    float: left;
    clear: none;
    margin-right: 20px;

    img {
        float: left;
    }

    span.info {
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        padding: 5px;
        float: left;
        clear: both;
        color: #fff;
        background: #000;
        background: rgba(0,0,0,.7);
    }
}

.img.right {
    position: relative;
    float: right;
    clear: none;
    margin-left: 20px;

    img {
        float: left;
    }
}

.related-articles {
    margin-left: 0px;

    li {
        list-style: none;
        margin-bottom: 20px;
    }
}

figure.fancy-caption {
    margin: 0 0 20px 0;
}

.lz-respecta.lz-container {
    z-index: 9999;

    @media (max-width: @screen-xs-max) {
        display: none !important;
    }
}

.blog-post-content {
    span.date {
        margin-bottom: 20px;
        margin-top: 10px;
        float: left;
        clear: both;
        width: 100%;
    }
}

.cmsplugin-catalog-product-image {
    max-height: 400px;
    padding-bottom: 15px;
}

.breadcrumb {
    .clearfix();
    padding: 0px;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    font-size: 1.3rem;

    > li {
        float: left;
        display: block;
        margin-right: 5px;

        + li::before {
            display: none;
        }

        a {
            display: block;
            float: left;
            padding-right: 5px;
        }
    }
}

.news-and-link-wrap {
    .sidebar {
        @media (max-width: @screen-xs-max) {
            margin-top: 40px;
        }
    }
}

.single-search-result {
    margin-bottom: 30px;
    display: block;
    color: @text-color;

    &:hover, &:focus {
        color: @link-color;
    }

    h2 {
        margin: 0px 0px 10px 0px;
        font-size: @font-size-h3;
    }

    p {
        margin: 0px;
    }
}

.form-designer {
    label {
        display: block;
        margin-bottom: 5px;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="email"],
    select {
        .form-control();
    }

    select[multiple] {
        height: auto;
    }

    textarea {
        .form-control();
        height: 125px;
        max-width: 100%;
    }
}

.before-content-block {
    .row();

    .breadcrumb {
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: @screen-md-min) {
            float: left;
            width: 50%;
            padding-top: 5px;
        }
    }

    .readspeaker-container {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;

        @media (min-width: @screen-md-min) {
            float: left;
            width: 50%;
            text-align: right;
            margin-bottom: 5px;
        }

        .rsbtn {
            display: inline-block;
        }
    }
}
