.teaser-wrap {
    margin-bottom: 20px;

    .teaser-column {
        &.last {
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                .clearfix();
                clear: both;
                float: none;
                margin: 0 auto;
            }
        }
    }
}
