.page-hero {
    .container();

    body.index & {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
        width: 100%;

        @media (min-width: @screen-sm-min) {
            width: 100%;
        }

        @media (min-width: @screen-md-min) {
            width: 100%;
        }

        @media (min-width: @screen-lg-min) {
            width: 1400px;
        }
    }

    .flexslider {
        margin-bottom: 40px;

        .flex-direction-nav {
            display: none;
        }

        .flex-control-nav.flex-control-paging {
            bottom: auto;
            right: 15px;
            top: 15px;
            width: auto;
            z-index: 101;

            li {
                margin: 0 4px;

                a {
                    width: 14px;
                    height: 14px;
                    background: @obo-greyish;
                    box-shadow: none;

                    &.flex-active {
                        background: @obo-life-long-blue;
                    }
                }
            }
        }

        ul.slides {
            > li {
                position: relative;
                width: 100%;
            }
        }

        .caption-container {
            position: absolute;
            bottom: 5px;
            left: 5px;
            right: 5px;
            z-index: 100;

            @media (min-width: @screen-sm-min) {
                bottom: 30px;
                left: percentage(1 / 12, 6);
                right: percentage(3 / 12, 6);
            }

            @media (min-width: @screen-md-min) {
                bottom: auto;
                top: 50%;
                left: percentage(1 / 12, 6);
                right: percentage(4 / 12, 6);
                transform: translate(0%, -50%);
            }

            @media (min-width: @screen-lg-min) {
                right: percentage(6 / 12, 6);
            }
        }

        .slide-caption {
            color: #fff;
            font-size: @font-size-base;
            line-height: 1.2;
            font-family: @font-family-title;
            padding: 7px;
            background: @obo-greyish;
            background: fade(@obo-greyish, 65%);

            @media (min-width: @screen-sm-min) {
                padding: 15px 30px;
                font-size: @font-size-h2;
            }

            @media (min-width: @screen-md-min) {
                font-size: @font-size-h1;
            }
        }
    }
}
