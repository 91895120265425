.rsbtn_colorskin {
    float: none;
    display: inline-block;
    border-color: transparent;
    color: @text-muted;

    .rspart {
        .rsbutton {
            background-color: @text-muted;

            &:hover {
                background-color: @brand-primary;
            }
        }

        .rsbtn_pause {
            margin-left: 0;
        }
    }

    .rsbtn_box {
        padding: 4px;
        border-radius: 2px;
        border: 1px solid @border-color;
        margin: 0;
        margin-left: 8px;
    }

    .rsbtn_powered {
        margin-top: 3px;
        right: 0;
        top: 100%;

        .rsbtn_btnlabel a span.rsbtn_label_icon.rsimg {
            display: none;
        }
    }

    .rsbtn_play {
        padding: 5px 0;
        margin: 0;

        &:hover, &:focus {
            .rsbtn_text {
                color: @brand-primary;
            }
        }
    }

    .rsbtn_left .rsbtn_text {
        background: none;
        color: @text-muted;
        position: relative;
        font-weight: 700;
        text-transform: uppercase;

        &:before {
            font-weight: normal;
            font-size: 1.2em;
            content: @fa-var-volume-up;
            font-family: FontAwesome;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .rsbtn_progress_container {
        border: 2px solid @text-muted;
        height: 12px;
    }

    .rsbtn_progress_played {
        background-color: rgba(0, 0, 0, 0.15);
    }

    .rsbtn_progress_handle.rsimg {
        background-color: @brand-primary;
        height: 18px;
        border-radius: 2px;
        top: -5px;
    }

    .rsbtn_volume_container {
        z-index: 120;
        margin-top: -3px;
        border: 1px solid @border-color;
        background-color: #fff;
        border-radius: 2px;
    }

    .rsbtn_volume_slider {
        background-color: rgba(0, 0, 0, 0.15);
        margin: 5px 4px;
    }

    .rsbtn_volume_handle.rsimg {
        border-radius: 2px;
        background: @brand-primary;
    }

    &.rspopup {
        display: none;
        position: absolute;
        background: #fff;
        box-shadow: 0 0 5px #999;
        border: 1px solid @text-muted;
        padding: 1px 5px;
    }
}
