.respecta-responsive-table {
    word-break: normal;
    border-collapse: collapse;

    @media (max-width: @screen-xs-max) {
        display: block;
        width: 100%;

        tbody, thead, tfoot, tr, th, td {
            display: block;
            width: 100%;
        }

        tr {
            margin: 10px 0;
        }

        td {
            padding: 10px 5px;
        }
    }
}
