.subnavi {
    list-style: none;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 0;

    &:empty {
        display: none;
    }

    ul {
        padding-left: 15px;
    }

    li {
        margin-bottom: 10px;
        list-style: none;

        &:before {
            content: @fa-var-angle-right;
            font-family: FontAwesome;
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            display: inline-block;
            text-decoration: inherit;
            -webkit-font-smoothing: antialiased;
        }

        &.ancestor,
        &.is-parent.selected {
            &:before {
                content: @fa-var-angle-down;
            }
        }

        &.selected a {
            color: @text-color;
        }

        &.descendant a {
            color: @link-color;
        }

        ul {
            margin-top: 10px;
        }

        a {
            padding-left: 5px;
        }

        .mobile-nav-toggle-btn {
            display: none;
        }
    }
}
