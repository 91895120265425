/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */


/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {
    outline: none;
}
.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
    margin: 0;
    padding: 0;
}

.lt-ie9 .flexslider li {
    width: 100%;
}

.lt-ie9 #partners .flex-control-nav {
    display: inline;
    text-align: center;
}

.logo-slider-wrap {
    .flexslider.logoslider {
        position: relative;
    }

    .logo-carousel-slide {
        width: 100/6 + 0%;
        float: left;
        padding: 0px 20px;
        margin-bottom: 0px;
        .box-sizing(border-box);

        @media (max-width: 767px) {
            width: 100/3 + 0%;
            margin-bottom: 40px;
        }
    }

    .flex-direction-nav a {
        position: absolute;
        top: 50%;
        margin-top: -20px;

        &.flex-prev {
            background: url('../img/logo-carousel-arrow.png') no-repeat;
            left: -20px;
            height: 40px;
            width: 40px;

            @media (max-width: 767px) {
                left: 0px;
            }
        }

        &.flex-next {
            background: url('../img/logo-carousel-arrow.png') no-repeat right;
            right: -20px;
            height: 40px;
            width: 40px;

            @media (max-width: 767px) {
                right: 0px;
            }
        }

        @media (max-width: 767px) {
            margin-top:0;
            bottom: -37px;
            top: auto;
        }
    }

    .flex-control-nav {
        bottom: -30px;
    }
}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.single-image .flexslider .slides > li {display: block; float: left;}
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}


/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .slides {display: block;}
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
/* Fix fade bug in IE7-8 */
.lt-ie9 .flexslider * { opacity: inherit; filter: inherit; }
.flexslider {margin: 0 0 0px; position: relative; zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides {zoom: 1;}

.carousel li {margin-right: 5px}

/* Direction Nav */
.flex-direction-nav {*height: 0;}
.flex-direction-nav a {width: 40px; height: 60px; margin: -60px 0 0; display: block; background: #fff url("../img/bg_direction_nav.png") no-repeat 0 0; position: absolute; top: 50%; z-index: 100; cursor: pointer; text-indent: -9999px; -webkit-transition: all .3s ease;}
.flex-direction-nav .flex-next {background-position: 100% 0; right: 0px; border-right: 0px;}
.flex-direction-nav .flex-prev {left: 0px; border-left: 0px;}
.flexslider:hover .flex-next {opacity: 1;}
.flexslider:hover .flex-prev {opacity: 1;}
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {opacity: 1;}
.flex-direction-nav .flex-disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: 20px; text-align: center; z-index: 100;}
.flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; box-shadow: inset 0 0 3px rgba(0,0,0,0.3);}
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}
